import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IUserSlice } from "interfaces/redux.interface";
import { IUser, TabDetailsType } from "interfaces/common.interface";
import {
  IMenuItems,
  IPopupItems,
} from "atic-common-helpers/helpers/interface.helper";
import { IStateList, IUserBageStore, IUserTable, IVehicleDetails, Permission } from "interfaces/api.interface";

const initialUserState: IUserSlice = {
  token: null,
  user: {} as IUser,
  menuItems: [] as IMenuItems[],
  subMenuItems: [] as IMenuItems[],
  popupItems: [] as IPopupItems[],
  stateLists: [] as IStateList[],
  UserBadgeList: [] as IUserBageStore[],
  UserPermission: [] as Permission[],
  UserKYCPermission: {} as Permission,
  ProfileSteps: [] as TabDetailsType[],
  profileDetails: {} as IUserTable,
  vehicleDetails: {} as IVehicleDetails,
  vehicleFormDataDetails: null,
  imageUpload: null
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setTokenRedux(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setUserRedux(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
    },
    setMenuItemsRedux(state, action: PayloadAction<IMenuItems[]>) {
      state.menuItems = action.payload;
    },
    setSubMenuItemsRedux(state, action: PayloadAction<IMenuItems[]>) {
      state.subMenuItems = action.payload;
    },
    setPopupItemsRedux(state, action: PayloadAction<IPopupItems[]>) {
      state.popupItems = action.payload;
    },
    setStateListsRedux(state, action: PayloadAction<IStateList[]>) {
      state.stateLists = action.payload;
    },
    setStateUserBadges(state, action: PayloadAction<IUserBageStore[]>) {
      state.UserBadgeList = action.payload;
    },
    setStateUserPermission(state, action: PayloadAction<Permission[]>) {
      state.UserPermission = action.payload;
    },
    setStateUserKYCPermission(state, action: PayloadAction<Permission>) {
      state.UserKYCPermission = action.payload;
    },
    setProfileSteps(state, action: PayloadAction<TabDetailsType[]>) {
      state.ProfileSteps = action.payload;
    },
    setProfileDetails(state, action: PayloadAction<IUserTable>) {
      state.profileDetails = action.payload;
    },
    setVehicleDetails(state, action: PayloadAction<IVehicleDetails | any>) {
      state.vehicleDetails = action.payload;
    },
    setVehicleFormDataDetails(state, action: PayloadAction<IVehicleDetails | any>) {
      state.vehicleFormDataDetails = action.payload;
    },
    setImage(state: any, action: any) {
      console.log(action)
      state.imageUpload = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialUserState);
    });
  },
});

export const { setTokenRedux, setUserRedux, setMenuItemsRedux, setSubMenuItemsRedux, setPopupItemsRedux, setStateListsRedux, setStateUserBadges,
  setProfileSteps, setProfileDetails, setVehicleDetails, setStateUserPermission, setStateUserKYCPermission, setVehicleFormDataDetails, setImage } =
  userSlice.actions;

export default userSlice.reducer;

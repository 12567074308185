import { toast, ToastOptions } from "react-toastify";
import { Toast } from "react-toastify/dist/types";

const options:ToastOptions = {
  type: toast.TYPE.INFO,
  hideProgressBar: false,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  progress: undefined,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  autoClose: false,
};

export const Success = (message: string) => {
  closeToaster()
  const success = { ...options, type: toast.TYPE.SUCCESS , newestOnTop: true };
  return toast.success(message, { ...success, theme: "light"  });
};

export const Failed = (message: string) => {
  closeToaster()
  const success = { ...options, type: toast.TYPE.ERROR , newestOnTop: true };
  return toast.error(message, { ...success, theme: "light" });
};

export const Info = (message: string) => {
  const success = { ...options, type: toast.TYPE.INFO , newestOnTop: true };
  return toast.info(message, { ...success, theme: "light" });
};

export const Warning = (message: string) => {
  const success = { ...options, type: toast.TYPE.WARNING  , newestOnTop: true};
  return toast.error(message, { ...success, theme: "light" });
};

export const closeToaster = () => {
    toast.dismiss(); // Close the toaster using the reference
};

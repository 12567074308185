import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "./localstorage.helper";
import { useAppDispatch, useAppSelector } from "store/store";
import { commonService } from "atic-common-helpers/helpers/common.service";
import { clearStore } from "utils/redux.utils";
import userSlice, {
  setMenuItemsRedux,
  setPopupItemsRedux,
  setStateListsRedux,
  setStateUserKYCPermission,
  setStateUserPermission,
  setSubMenuItemsRedux,
  setUserRedux,
} from "store/slice/user.slice";
import menuItems from "container/layout/menu-items";
import popupItems from "utils/popup.constant";
import { parsePhoneNumberformat } from "atic-common-helpers/hooks/customHook";
import { roles } from "features/theme/constant";
import { IProfileReponse, IStateListResponse, Permission } from "interfaces/api.interface";
import { memoryUsage } from "process";
import {
  emptyToolBar,
  fleetOperatorHouseToolBar,
  vehicleOwnerHouseToolBar,
  agentHouseToolBar,
  servicesToolBar,
  driverHouseToolBar,
  vehicle,
  vehiclePermit,
  dongle,
  serviceProviderHouseToolBar,
  HousesToolBar,
  credentialingChild,
} from "container/layout/menu-items/profile";

export const AuthContext: any = createContext({});

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useLocalStorage(
    "at-dc-token",
    sessionStorage.getItem("at-dc-token")
  );
  const navigate = useNavigate();
  const location = useLocation();

  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  const [countryCode, setCountrycode] = useState<string>("US");

  useEffect(() => {
    if (token) {
      getUser("");
      getStateList();
    }
  }, []);

  const getStateList = async () => {
    try {
      const stateResponse: IStateListResponse = await commonService.postService(
        `/v1/public/state_list/${countryCode}`,
        {}
      );
      if (!!stateResponse) {
        let stateList: any = stateResponse.data.data;
        dispatch(setStateListsRedux(stateList));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = async (navigateParams: any) => {
    try {
      const user: IProfileReponse = await commonService.getServices(
        `/v2/profile/my_profile`
      );
      const userData = user?.data?.data;
      const setUserData = {
        email: userData.user?.email,
        firstname: userData.firstname,
        lastname: userData.lastname,
        dob: userData?.dob,
        id: userData.id,
        mobile_number:
          parsePhoneNumberformat(
            userData?.user?.country_code,
            userData?.user?.mobile_number
          ) || "",
        mobile_verified: userData?.mobile_verified,
        email_verified: userData?.email_verified,
        entra_verified: userData?.entra_verified,
        kyc_verified: userData?.kyc_verified,
        is_mobile_registered: userData?.user?.is_mobile_registered,
        is_driver_credentialed: userData?.user?.is_driver_credentialed,
        role: userData?.user?.role ? userData?.user?.role?.name : "profile",
        user_badge: userData?.user?.user_badges,
        profile_verified: userData?.profile_verified,
        user: {
          id: userData?.id,
          agent_user: {
            status: userData.user?.agent_user?.status,
            is_admin: userData.user?.agent_user?.is_admin,
          },
          fleet_user: {
            status: userData.user?.fleet_user?.status,
            is_admin: userData.user?.fleet_user?.is_admin,
          },
          user_vehicle_owner: {
            status: userData.user.user_vehicle_owner?.status,
            is_admin: userData.user.user_vehicle_owner?.is_admin,
          },
          driver: {
            state: userData?.user?.driver_user?.status,
            is_admin: userData?.user?.driver_user?.is_admin,
          },
          service_provider_user: {
            state: userData?.user?.service_provider_user?.status,
            is_admin: userData?.user?.service_provider_user?.is_admin,
          },
        },
      };
      let permission = user.data.permissions.map(
        (permissionOBJ: Permission) => {
          return {
            module: permissionOBJ.module,
            permission: permissionOBJ.permission,
          };
        }
      );
      let kycVerificationPermission: any;
      if (!!user.data.permissions) {
        kycVerificationPermission = user.data.permissions.find(
          (permissionOBJ: Permission) =>
            permissionOBJ.module == "verification" &&
            permissionOBJ.permission == "kyc"
        );
        // dispatch(setStateUserKYCPermission(kycVerificationPermission))
      }
      dispatch(setStateUserPermission(permission));
      dispatch(setUserRedux(setUserData));
      let toolBar: any = { ...emptyToolBar };
      let isAdmin = setUserData.user_badge?.find((item: any) => item?.badge?.name == 'admin');
      if (!isAdmin) {
        toolBar.children = toolBar?.children?.slice(0, 4);
      }
      if (isAdmin && process.env.REACT_APP_SERVER_NAME == 'production') {
        toolBar.children = toolBar?.children?.slice(0, 4);
      }
      let children: any = [];
      let subChildren: any = [];
      let menus = [...menuItems[setUserData.role]];
      let popupItem = popupItems[setUserData.role];
      if (
        setUserData?.is_mobile_registered &&
        setUserData?.is_driver_credentialed
      ) {
        menus = [...menuItems["mobileUserProfile"]];
        popupItem = popupItems[roles.profile];
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux(menus));
        return;
      }
      if (
        setUserData?.is_mobile_registered &&
        !setUserData?.is_driver_credentialed &&
        !setUserData.profile_verified
      ) {
        menus = [...menuItems["mobileUserProfileNotVerifed"]];
        popupItem = popupItems[roles.profile];
        popupItem = popupItem?.filter((item: any) => !item?.isMyWallet);
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux(menus));
        return;
      }
      if (
        setUserData?.is_mobile_registered &&
        !setUserData?.is_driver_credentialed &&
        (!setUserData.profile_verified ||
          (!!setUserData.email && !setUserData?.email_verified))
      ) {
        menus = [...menuItems["mobileUserProfileNotVerifed"]];
        popupItem = popupItems[roles.profile];
        popupItem = popupItem?.filter((item: any) => !item?.isMyWallet);
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux(menus));
        return;
      }
      if (
        setUserData?.is_mobile_registered &&
        !setUserData?.is_driver_credentialed &&
        setUserData?.profile_verified
      ) {
        menus = [...menuItems["mobileUserProfileVerifed"]];
        popupItem = popupItems[roles.profile];
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux(menus));
        return;
      }
      if (
        !setUserData.mobile_verified ||
        !setUserData?.profile_verified ||
        (!!setUserData.email && !setUserData?.email_verified)
      ) {
        menus = [...menuItems[roles.profile]];
        popupItem = popupItems[roles.profile];
        popupItem = popupItem?.filter((item: any) => !item?.isMyWallet);
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux(menus));
        return;
      }

      if (
        setUserData.mobile_verified &&
        setUserData?.profile_verified &&
        !userData.user?.user_badges?.length
      ) {
        toolBar.children[1].children.push({ ...servicesToolBar });
        popupItem = popupItems[roles.profile];
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux(toolBar));
        return;
      }
      if (
        setUserData.mobile_verified &&
        setUserData?.profile_verified &&
        !!setUserData.email &&
        setUserData?.email_verified &&
        !userData.user?.user_badges?.length
      ) {
        toolBar.children[1].children.push({ ...servicesToolBar });
        popupItem = popupItems[roles.profile];
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux(toolBar));
        return;
      }
      if (!!user.data.permissions.length) {
        children.push({ ...servicesToolBar });
        user.data.permissions.forEach((permissionOBJ: any) => {
          if (
            permissionOBJ.module == "vehicle_permit" &&
            permissionOBJ.permission == "list"
          ) {
            children.push({ ...vehiclePermit });
          }
          if (
            permissionOBJ.module == "vehicle" &&
            permissionOBJ.permission == "list"
          ) {
            children.push({ ...vehicle });
          }
          if (
            permissionOBJ.module == "plugin" &&
            permissionOBJ.permission == "list"
          ) {
            children.push({ ...dongle });
          }
          /* if (
            permissionOBJ.module == "agent_house" &&
            permissionOBJ.permission == "list"
          ) {
            subChildren.push({ ...agentHouseToolBar });
          }
          if (
            permissionOBJ.module == "fleet_operator_house" &&
            permissionOBJ.permission == "list"
          ) {
            subChildren.push({ ...fleetOperatorHouseToolBar });
          }
          if (
            permissionOBJ.module == "vehicle_owner_house" &&
            permissionOBJ.permission == "list"
          ) {
            subChildren.push({ ...vehicleOwnerHouseToolBar });
          }
          if (
            permissionOBJ.module == "service_provider_house" &&
            permissionOBJ.permission == "list"
          ) {
            subChildren.push({ ...serviceProviderHouseToolBar });
          } */
        });
        /*  if (!!subChildren.length) {
           let houses_toolbar = { ...HousesToolBar };
           houses_toolbar.children = [...subChildren];
           children.push({ ...houses_toolbar });
         } */
        let toolBarFirstChild = toolBar.children[1];
        toolBarFirstChild = {
          ...toolBarFirstChild,
          children: children
        }
        if (navigateParams == '/admin/siteadministration/adminusers') {
          dispatch(setPopupItemsRedux(popupItems[roles.profile]));
          dispatch(setMenuItemsRedux([toolBar]));
          dispatch(setSubMenuItemsRedux(toolBar.children[5].children))
          navigate(navigateParams);
          return;
        }
        toolBar.children[1] = toolBarFirstChild;
        menus = [toolBar];
        popupItem = popupItems[roles.profile];
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux(menus));
        if (!!navigateParams) {
          navigate(navigateParams);
        }
        return;
      }
      if (!!userData.user.user_badges.length) {
        /*  userData.user.user_badges.forEach((item: any) => {
              if (
                item.badge.name === "agent" &&
                item?.entra_verified &&
                userData?.user?.agent_user?.is_admin
              ) {
                subChildren.push({ ...agentHouseToolBar });
              }
              if (
                item.badge.name === "fleet_operator" &&
                item?.entra_verified &&
                userData?.user?.fleet_user?.is_admin
              ) {
                subChildren.push({ ...fleetOperatorHouseToolBar });
              }
              if (
                item.badge.name === "vehicle_owner" &&
                item?.entra_verified &&
                userData?.user?.user_vehicle_owner?.is_admin
              ) {
                subChildren.push({ ...vehicleOwnerHouseToolBar });
              }
              if (
                item.badge.name === "service_provider" &&
                item?.entra_verified &&
                userData?.user?.service_provider_user?.is_admin
              ) {
                subChildren.push({ ...serviceProviderHouseToolBar });
              }
            }); */
        /* if (
              userData?.user?.agent_user?.is_admin ||
              userData?.user?.fleet_user?.is_admin ||
              userData?.user?.user_vehicle_owner?.is_admin ||
              userData?.user?.service_provider_user?.is_admin
            ) {
              let houses_toolbar = { ...HousesToolBar };
              houses_toolbar.children = [...subChildren];
              children.push({ ...houses_toolbar });
            } */
        toolBar.children = children;
        menus = [toolBar];
        popupItem = popupItems[roles.profile];
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux(menus));
        if (!!navigateParams) {
          navigate(navigateParams);
        }
        return;
      }
      console.log("Toolbar: ", toolBar);
      console.log("Children: ", children);
      console.log("Subchldren: ", subChildren);
    } catch (err) {
      console.log("err", err);
      clearStore();
      setToken(null);
      navigate("/login");
    }
  };

  const login = async (data: any) => {
    setToken(data?.token);
    await getUser("");
  };

  const logout = () => {
    // if (keycloak && keycloak.authenticated) {
    //   await keycloak.logout();
    // }
    clearStore();
    setToken(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
      user,
      getUser,
      getStateList
    }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth: any = () => {
  return useContext(AuthContext);
};

import { lazy, Suspense } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { role, roles } from "features/theme/constant";
import Loader from "../ui-component/Loader";
import { useAppSelector } from "store/store";
import QrVerification from "pages/qr-verification";
import { IBadge } from "atic-common-helpers/helpers/interface.helper";
import QrVerify from "pages/qr-verification/QrVerify";
// import { kycNotVerified } from "container/layout/menu-items/profile";

// Guest role
const PageNotFound = lazy(() => import("../pages/pagenotfound"));
const Register = lazy(() => import("../pages/authentication/Register"));
const Login = lazy(() => import("../pages/authentication/Login"));
const ChangePassword = lazy(
  () => import("../pages/authentication/ChangePassword")
);
const SetPassword = lazy(() => import("../pages/authentication/SetPassword"));
const VerifyEmail = lazy(() => import("../pages/authentication/VerifyEmail"));
const ForgotPassword = lazy(
  () => import("../pages/authentication/ForgotPassword")
);

/* mobile user login route */
const DifferentBroswerUserValidation = lazy(
  () => import("../pages/authentication/DifferentBrowserUserValidation")
);

//admin component
const Layout = lazy(() => import("atic-common-helpers/layout/MainLayout"));
const ComingSoon = lazy(() => import("ui-component/coming-soon"));

//profile completion
const Profile = lazy(() => import("../pages/profile"));
const ProfileCompletion = lazy(
  () => import("../pages/profile/profile-completion")
);
const ProfileCompletionForKYC = lazy(
  () => import("../pages/profile/profile-completion/kyc-profile")
);
const MobileUserProfileCompletion = lazy(
  () => import("../pages/profile/profile-completion/mobileUser")
);
const KYCReVerification = lazy(
  () => import("../pages/profile/profile-completion/kyc-re-verification")
);

/* credentialing service */
const CrendentialingServices = lazy(
  () => import("../pages/credentialing/credential-services")
);

// ServicesLanding Page Newservices
const ServicesLandingPage = lazy(() => import("../pages/services-landingpage"));
//const KYCRoute = lazy(()=> import("../pages/profile copy/profile-completion/"))

/* amdin */
const Admin = lazy(() => import("../pages/siteadminisitration/index"));

/* agent */
const AgentCreate = lazy(() => import("../pages/agent/agent-create-new"));
const AgentKYBVerification = lazy(
  () => import("../pages/agent/agent-kyb-verification")
);
const AgentQRVerification = lazy(
  () => import("../pages/agent/agent-qr-verification")
);
const AgentHouse = lazy(() => import("../pages/agent/agent-house"));
const AgentDetailsEdit = lazy(
  () => import("../pages/profile-details/badges-details-edit/AgentDetailsEdit")
);

/* driver */
const DriverCreate = lazy(() => import("../pages/driver/driver-create"));
const DriverQRVerification = lazy(
  () => import("../pages/driver/driver-qr-verification")
);
const DriverKYBVerification = lazy(
  () => import("../pages/driver/driver-kyb-verification")
);
const DriverHouse = lazy(() => import("../pages/driver/driver-house"));
const DriverDetailsEdit = lazy(
  () => import("../pages/profile-details/badges-details-edit/DriverDetailsEdit")
);

/* vehicle owner */
const VehicleOwnerCreate = lazy(
  () => import("../pages/vehicle-owner/vehicle-owner-create"))
const VehicleDetailsEdit = lazy(
  () => import("../pages/profile-details/badges-details-edit/VehicleOwnerEdit")
);
const VehicleOwnerOthersCreate = lazy(
  () => import("../pages/vehicle-owner/vehicle-owner-others-create")
);
const VehicleOwnerKYBVerification = lazy(
  () => import("../pages/vehicle-owner/vehicle-onwer-kyb-verification")
);
const VehicleOwnerQRVerification = lazy(
  () => import("../pages/vehicle-owner/vehicle-owner-qr-verification")
);
const VehicleOwnerHouse = lazy(
  () => import("../pages/vehicle-owner/vehicle-owner-house")
);

/* fleet operator */
const FleetOperatorCreate = lazy(
  () => import("../pages/fleet-operator/fleet-operator-create")
);
const FleetOperatorEdit = lazy(
  () => import("../pages/profile-details/badges-details-edit/FleetOperatorEdit")
);
const FleetOperatorOtherCreate = lazy(
  () => import("../pages/fleet-operator/fleet-operator-other-create")
);
const FleetKYBVerification = lazy(
  () => import("../pages/fleet-operator/fleet-kyb-verification")
);
const FleetQRVerification = lazy(
  () => import("../pages/fleet-operator/fleet-qr-verification")
);
const FleetOperatorHouse = lazy(
  () => import("../pages/fleet-operator/fleet-house")
);

/* service provider */
const ServiceProviderCreate = lazy(
  () => import("../pages/service-provider/service-provider-create")
);
const ServiceProviderEdit = lazy(
  () =>
    import("../pages/profile-details/badges-details-edit/ServiceProviderEdit")
);
const ServiceProviderOthersCreate = lazy(
  () => import("../pages/service-provider/service-provider-others-create")
);
const ServiceProviderKYBVerification = lazy(
  () => import("../pages/service-provider/service-provider-kyb-verification")
);
const ServiceProviderQRVerification = lazy(
  () => import("../pages/service-provider/service-provider-qr-verification")
);
const ServiceProviderHose = lazy(
  () => import("../pages/service-provider/service-provider-house")
);

/* vehicle */
const VehicleCreate = lazy(() => import("../pages/vehicle/vehicle-create"));
const VehicleList = lazy(() => import("../pages/vehicle/vehicle-list"));

/* vehicle permit */
const VehiclePermitCreate = lazy(
  () => import("../pages/vehicle-permit/vehicle-permit-create")
);
const VehiclePermitList = lazy(
  () => import("../pages/vehicle-permit/vehicle-permit-list")
);

/* dongle and obd */
const DongleCreate = lazy(() => import("../pages/dongle/dongle-create"));
const DongleList = lazy(() => import("../pages/dongle/dongle-list"));

/* other profile completion */
const OthersProfileCompletion = lazy(
  () => import("../pages/profile/profile-completion/otherCompletion")
);

//driver component
const DriverVerfication = lazy(
  () => import("../atic-common-helpers/pages/verification")
);
const ViewProfile = lazy(
  () => import("../atic-common-helpers/component/driver-details/driverDetails")
);

//Profile Details
const ProfileDetails = lazy(
  () => import("../pages/profile-details/profile-basic-details")
);
const BadgeMenu = lazy(() => import("../pages/profile-details/badges-menu"));
const ProfileEdit = lazy(() => import("../pages/profile/profile-edit"));

// roles
const { admin, driver, profile } = roles;

//Guest role authentication
const GuestRoute = (props: any) => {
  const user = useAppSelector((state) => state.user.user);
  const userKYCVerificationPermission: any = useAppSelector(
    (state) => state.user.UserKYCPermission
  );
  let location = useLocation();
  if (user && Object.keys(user).length > 0) {
    return (
      <Navigate
        to={
          user?.is_mobile_registered &&
            user?.is_driver_credentialed &&
            !!user.profile_verified
            ? "profile_details"
            : user?.is_mobile_registered &&
              user?.is_driver_credentialed &&
              !user.profile_verified
              ? "profile_details"
              : `/${user.role}${user.role == "profile" &&
                user?.profile_verified &&
                !!user.email &&
                user?.email_verified &&
                !user?.is_mobile_registered
                ? "/services_menu"
                : user.role == "profile" &&
                  user?.is_mobile_registered &&
                  !user?.is_driver_credentialed &&
                  (!user.profile_verified ||
                    (!!user.email && !user?.email_verified))
                  ? "/completion/mobile_user_verification"
                  : user.role == "profile" &&
                    user?.is_mobile_registered &&
                    !user?.is_driver_credentialed &&
                    user.profile_verified
                    ? "/services_menu"
                    : user.role == "profile" &&
                      (!user?.mobile_verified ||
                        !user?.profile_verified ||
                        (!!user.email && !user?.email_verified))
                      ? "/completion/verification"
                      : "/services_menu"
              }`
        }
        state={{ from: location }}
        replace
      />
    );
  }
  return <Outlet />;
};

//kyc and kyb before verification authentication
const ProductedKYCRoute = (props: any) => {
  const user = useAppSelector((state) => state.user.user);
  const userKYCVerificationPermission: any = useAppSelector(
    (state) => state.user.UserKYCPermission
  );
  const location = useLocation();
  if (user && Object.keys(user).length === 0) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (
    user?.mobile_verified &&
    user?.profile_verified &&
    userKYCVerificationPermission &&
    user?.kyc_verified &&
    !!user.email &&
    user?.email_verified &&
    !user?.is_mobile_registered
  ) {
    return (
      <Navigate
        to={`/${roles.profile}/services`}
        state={{ from: location }}
        replace
      />
    );
  } else if (
    user?.mobile_verified &&
    user?.profile_verified &&
    !userKYCVerificationPermission &&
    !user?.kyc_verified &&
    !!user.email &&
    user?.email_verified &&
    !user?.is_mobile_registered
  ) {
    return (
      <Navigate
        to={`/${roles.profile}/services`}
        state={{ from: location }}
        replace
      />
    );
  } else if (
    user?.mobile_verified &&
    user?.profile_verified &&
    userKYCVerificationPermission &&
    user?.kyc_verified &&
    !!user.email &&
    user?.email_verified &&
    user?.is_mobile_registered
  ) {
    return (
      <Navigate
        to={`/${roles.profile}/services`}
        state={{ from: location }}
        replace
      />
    );
  } else if (
    user?.mobile_verified &&
    user?.profile_verified &&
    userKYCVerificationPermission &&
    user?.kyc_verified &&
    !user.email &&
    !user?.is_mobile_registered
  ) {
    return (
      <Navigate
        to={`/${roles.profile}/services`}
        state={{ from: location }}
        replace
      />
    );
  } else if (
    user?.mobile_verified &&
    user?.profile_verified &&
    !userKYCVerificationPermission &&
    !user?.kyc_verified &&
    !user.email &&
    !user?.is_mobile_registered
  ) {
    return (
      <Navigate
        to={`/${roles.profile}/services`}
        state={{ from: location }}
        replace
      />
    );
  } else if (
    user?.mobile_verified &&
    user?.profile_verified &&
    !user.email &&
    user?.is_mobile_registered
  ) {
    return (
      <Navigate
        to={`/${roles.profile}/services`}
        state={{ from: location }}
        replace
      />
    );
  } else if (props?.roles?.includes(user?.role)) {
    return <Outlet />;
  } else {
    return <Navigate to="/not-found" state={{ from: location }} replace />;
  }
};

//kyc after verification authentication
const ProductedKYCVerifiedRoute = (props: any) => {
  const user = useAppSelector((state) => state.user.user);
  const userKYCVerificationPermission: any = useAppSelector(
    (state) => state.user.UserKYCPermission
  );
  const isEmpty = (userKYCVerificationPermission: any) =>
    Object.keys(userKYCVerificationPermission).length === 0;
  let location = useLocation();
  if (user && Object.keys(user).length === 0) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (
    user?.is_mobile_registered &&
    (!user?.mobile_verified ||
      !user?.profile_verified ||
      (!!user.email && !user?.email_verified))
  ) {
    return (
      <Navigate
        to={`/${roles.profile}/completion/mobile_user_verification`}
        state={{ from: location }}
        replace
      />
    );
  }
  // else if (!user?.is_mobile_registered && (!user?.mobile_verified || !user?.profile_verified || ((!isEmpty(userKYCVerificationPermission) ? (userKYCVerificationPermission && !user.kyc_verified) : false)) || (!!user.email && !user?.email_verified))) {
  //   return (
  //     <Navigate to={`/${roles.profile}/completion/verification`} state={{ from: location }} replace />
  //   );
  // }
  else if (props?.roles?.includes(user?.role)) {
    return <Outlet />;
  } else {
    return <Navigate to="not-found" state={{ from: location }} replace />;
  }
};

//credentialing after
const AfterCredentialingRoute = (props: any) => {
  const user = useAppSelector((state) => state.user.user);
  let location = useLocation();
  if (user && Object.keys(user).length === 0) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!user?.user_badge?.length) {
    return (
      <Navigate
        to={`/${roles.profile}/services_menu`}
        state={{ from: location }}
        replace
      />
    );
  } else if (!!user?.user_badge?.length) {
    return <Outlet />;
  } else {
    return <Navigate to="not-found" state={{ from: location }} replace />;
  }
};

/* producted route */
const ProductedRoute = (props: any) => {
  const user = useAppSelector((state) => state.user.user);
  let token = sessionStorage.getItem("at-dc-token");
  let location = useLocation();
  if (user && Object.keys(user).length === 0) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!!token) {
    return <Outlet />;
  } else {
    return <Navigate to="not-found" state={{ from: location }} replace />;
  }
};

const RoutePath = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/">
          <Route path="*" element={<PageNotFound />} />
          <Route path="not-found" element={<PageNotFound />} />
          <Route path="/" element={<Navigate to="login" replace />} />
          <Route path={`/qr_verification/:id`} element={<QrVerify />} />
          <Route path="/auth_verification_token" element={<Login />} />
          {/* Guest routes */}
          <Route path="/register" element={<Login />} />
          <Route element={<GuestRoute role={[role]} />}>
            <Route path="/login" element={<Login />} />
            <Route path="/verify_email" element={<VerifyEmail />} />
            <Route path="/setpassword/:verifyToken" element={<SetPassword />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/getUser/:id"
              element={<DifferentBroswerUserValidation />}
            />
          </Route>
          <Route path="404" element={<PageNotFound />} />
          <Route element={<Layout />}>
            {/* producted route */}
            <Route element={<ProductedRoute role={[profile]} />}>
              <Route path={`/change_password`} element={<ChangePassword />} />
              <Route path={`/profile_details`} element={<ProfileDetails />} />
              <Route path={`/profile_edit`} element={<ProfileEdit />} />
              <Route path={`/user_badge_details`} element={<BadgeMenu />} />

              <Route path={`/dashboard/coming_soon`} element={<ComingSoon />} />
              <Route path={`/services/coming_soon`} element={<ComingSoon />} />
              <Route path={`/site_admin/coming_soon`} element={<ComingSoon />} />
              <Route path={`/member_portal/coming_soon`} element={<ComingSoon />} />
              <Route path={`/my_business/coming_soon`} element={<ComingSoon />} />
              <Route path={`/reports/coming_soon`} element={<ComingSoon />} />
              <Route path={`/coming_soon_1`} element={<ComingSoon />} />
              <Route path={`/coming_soon_2`} element={<ComingSoon />} />

            </Route>

            {/* profile completion */}
            <Route element={<ProductedKYCRoute roles={[profile]} />}>
              <Route
                path={`/${roles.profile}/completion`}
                element={<Profile />}
              />
              <Route
                path={`/${roles.profile}/completion/verification`}
                element={<ProfileCompletion />}
              />
              <Route
                path={`/${roles.profile}/completion/mobile_user_verification`}
                element={<MobileUserProfileCompletion />}
              />
            </Route>

            {/* profile credentialing service */}
            <Route element={<ProductedKYCVerifiedRoute roles={[profile]} />}>
              <Route
                path={`/${roles.profile}/services`}
                element={<CrendentialingServices />}
              />
              <Route
                path={`/${roles.profile}/services_menu`}
                element={<ServicesLandingPage />}
              />
              <Route
                path={`/${roles.profile}/kyc_verification`}
                element={<ProfileCompletionForKYC />}
              />
              <Route
                path={`/${roles.profile}/kyc_re_verification`}
                element={<KYCReVerification />}
              />
              <Route
                path={`/${roles.profile}/agent_create`}
                element={<AgentCreate />}
              />
              <Route
                path={`/${roles.profile}/agent_house`}
                element={<AgentHouse />}
              />
              <Route
                path={`/${roles.profile}/agent_edit`}
                element={<AgentDetailsEdit />}
              />
              <Route
                path={`/${roles.profile}/agent_kyb_verification`}
                element={<AgentKYBVerification />}
              />
              {/* <Route path={`/${roles.profile}/agent_qr_verification`} element={<AgentQRVerification />} /> */}
              <Route
                path={`/${roles.profile}/agent_qr_verification`}
                element={
                  <QrVerification
                    header="Service Representative"
                    type={IBadge.AGENT}
                  />
                }
              />
              <Route
                path={`/${roles.profile}/driver_create`}
                element={<DriverCreate />}
              />
              <Route
                path={`/${roles.profile}/driver_edit`}
                element={<DriverDetailsEdit />}
              />
              <Route
                path={`/${roles.profile}/driver_kyb_verification`}
                element={<DriverKYBVerification />}
              />
              {/* <Route path={`/${roles.profile}/driver_qr_verification`} element={<DriverQRVerification />} /> */}
              <Route
                path={`/${roles.profile}/driver_qr_verification`}
                element={
                  <QrVerification header="Driver" type={IBadge.DRIVER} />
                }
              />
              {/* <Route path={`/${roles.profile}/driver_house`} element={<DriverHouse />} /> */}
              <Route
                path={`/${roles.profile}/fleet_operator_create`}
                element={<FleetOperatorCreate />}
              />
              <Route
                path={`/${roles.profile}/fleet_operator_edit`}
                element={<FleetOperatorEdit />}
              />
              <Route
                path={`/${roles.profile}/fleet_operator_other_create`}
                element={<FleetOperatorOtherCreate />}
              />
              <Route
                path={`/${roles.profile}/fleet_kyb_verification`}
                element={<FleetKYBVerification />}
              />
              {/* <Route path={`/${roles.profile}/fleet_qr_verification`} element={<FleetQRVerification />} /> */}
              <Route
                path={`/${roles.profile}/fleet_qr_verification`}
                element={
                  <QrVerification
                    header="Fleet Operator"
                    type={IBadge.FLEET_OPERATOR}
                  />
                }
              />
              <Route
                path={`/${roles.profile}/fleet_operator_house`}
                element={<FleetOperatorHouse />}
              />
              <Route
                path={`/${roles.profile}/vehicle_owner_create`}
                element={<VehicleOwnerCreate />}
              />
              <Route
                path={`/${roles.profile}/vehicle_owner_edit`}
                element={<VehicleDetailsEdit />}
              />
              <Route
                path={`/${roles.profile}/vehicle_owner_kyb_verification`}
                element={<VehicleOwnerKYBVerification />}
              />
              {/* <Route path={`/${roles.profile}/vehicle_owner_qr_verification`} element={<VehicleOwnerQRVerification />} /> */}
              <Route
                path={`/${roles.profile}/vehicle_owner_qr_verification`}
                element={
                  <QrVerification
                    header="Vehicle Owner"
                    type={IBadge.VEHICLE_OWNER}
                  />
                }
              />
              <Route
                path={`/${roles.profile}/vehicle_owner_house`}
                element={<VehicleOwnerHouse />}
              />
              <Route
                path={`/${roles.profile}/vehicle_owner_other_create`}
                element={<VehicleOwnerOthersCreate />}
              />
              <Route
                path={`/${roles.profile}/service_provider_create`}
                element={<ServiceProviderCreate />}
              />
              <Route
                path={`/${roles.profile}/service_provider_edit`}
                element={<ServiceProviderEdit />}
              />
              <Route
                path={`/${roles.profile}/service_provider_other_create`}
                element={<ServiceProviderOthersCreate />}
              />
              <Route
                path={`/${roles.profile}/service_provider_kyb_verification`}
                element={<ServiceProviderKYBVerification />}
              />
              {/* <Route path={`/${roles.profile}/service_provider_qr_verification`} element={<ServiceProviderQRVerification />} /> */}
              <Route
                path={`/${roles.profile}/service_provider_qr_verification`}
                element={
                  <QrVerification
                    header="Service Provider"
                    type={IBadge.SERVICE_PROVIDER}
                  />
                }
              />
              <Route
                path={`/${roles.profile}/service_provider_house`}
                element={<ServiceProviderHose />}
              />
              <Route
                path={`/${roles.profile}/others_profile_completion`}
                element={<OthersProfileCompletion />}
              />
            </Route>
            <Route element={<ProductedKYCVerifiedRoute roles={[profile]} />}>
              <Route
                path={`/${admin}/siteadministration/adminusers`}
                element={<Admin />}
              />
            </Route>
            <Route element={<AfterCredentialingRoute roles={[profile]} />}>
              <Route
                path={`/${roles.profile}/vehicle_create`}
                element={<VehicleCreate />}
              />
              <Route
                path={`/${roles.profile}/vehicle_edit`}
                element={<VehicleCreate />}
              />
              <Route
                path={`/${roles.profile}/vehicle_list`}
                element={<VehicleList />}
              />
              <Route
                path={`/${roles.profile}/vehicle_permit_create`}
                element={<VehiclePermitCreate />}
              />
              <Route
                path={`/${roles.profile}/vehicle_permit_edit`}
                element={<VehiclePermitCreate />}
              />
              <Route
                path={`/${roles.profile}/vehicle_permit_list`}
                element={<VehiclePermitList />}
              />
              <Route
                path={`/${roles.profile}/dongle_create`}
                element={<DongleCreate />}
              />
              <Route
                path={`/${roles.profile}/dongle_edit`}
                element={<DongleCreate />}
              />
              <Route
                path={`/${roles.profile}/dongle_list`}
                element={<DongleList />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutePath;

import Swal from "sweetalert2";

type sweetalertProps = {
  status?: number;
  message: string;
  title: string;
  onSuccess?: Function;
  onError?: Function;
  confirmButtonText?: string;
};

const SweetAlertComponent = ({
  status,
  title,
  confirmButtonText,
  onSuccess,
  message,
  onError,
}: sweetalertProps) => {
  return Swal.fire({
    icon: status === 200 ? "success" : status === 400 ? "error" : "warning",
    confirmButtonText: confirmButtonText ? confirmButtonText : 'Ok',
    title: title,
    text: message,
    allowOutsideClick: false,
  }).then(() => {
    if (onSuccess) {
      onSuccess();
    } else if (onError) {
      onError();
    }
  });
};

export default SweetAlertComponent;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import "./asset/scss/style.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store, { persistor } from "./store/store";
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import config from "./constant/config";
import { AuthProvider } from 'routes/auth.provider';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { KeycloackContextProvider } from 'pages/authentication/keycloak/useKeyCloak';

//google client id
const GoogeClientID: any = process.env.REACT_APP_GOOGLE_CLIENTID

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <GoogleOAuthProvider clientId={GoogeClientID}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename={config.basename}>
            <KeycloackContextProvider >
              <AuthProvider>
                <ToastContainer />
                <App />
              </AuthProvider>
            </KeycloackContextProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

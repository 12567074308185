import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SearchInputFiled } from "atic-common-helpers/hoc/formfield";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { Close } from "@mui/icons-material";

const MainContainerWrapper = ({
  children,
  ...props
}: React.PropsWithChildren<MainContainerWrapperType>) => {
  const {
    handleClick,
    search,
    handleSearch,
    headingText,
    buttonText,
    buttonIcon,
    isSearch = false,
    isCreateButton = false,
    bgColor,
    searchPlaceHolder,
    buttonBgColor,
    handleSearchClear
  } = props;
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: { xs: 1 },
        px: { md: 2, xs: 1 },
      }}
    >
      <Stack
        direction={matchDownMD ? "column" : "row"}
        alignItems="center"
        sx={{
          justifyContent: { md: "space-between", xs: "center" },
          // rowGap: { xs: ".5rem" },
          minHeight: { xs: "3rem", md: "3rem" },
          marginTop: 1
        }}
      >
        <Box>
          <Typography variant="h1" color={theme.palette.text.headingText}>{headingText}</Typography>
        </Box>
        <Box>
          <Stack
            direction={matchDownMD ? "column-reverse" : "row"}
            spacing={matchDownMD ? 0 : 3}
            sx={{
              rowGap: { xs: ".5rem" },
            }}
          >
            {isCreateButton && (
              <Button
                className="createBtn"
                variant="contained"
                onClick={handleClick}
                disableRipple
                sx={{
                  height: "3.1rem",
                  padding: "0 1rem",
                  backgroundColor: buttonBgColor || theme.palette.primary?.createButtonColor,
                  "&:hover": {
                    backgroundColor: buttonBgColor || theme.palette.primary?.createButtonColor,
                  },
                }}
                startIcon={buttonIcon}
              >
                {buttonText}
              </Button>
            )}
            {isSearch && (
              <SearchInputFiled
                values={search}
                handleChange={handleSearch}
                startIcon={<SearchIcon />}
                endIcon={<Close />}
                placeholder={searchPlaceHolder ? searchPlaceHolder : "Search"}
                handleSearchClear={handleSearchClear}
                errors={(search && search.length < 3) ? 'Please enter at least 3 characters' : null}
              />
            )}
          </Stack>
        </Box>
      </Stack>
      <Box
        mt={2}
        // mb={4}
        sx={{
          width: "100%",
          minHeight: "70vh",
          backgroundColor: bgColor ?? theme.palette.background.paper,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

type MainContainerWrapperType = {
  handleClick?: (_: any) => void;
  search?: string | null;
  handleSearch?: (_: any) => void;
  headingText: string;
  isSearch?: boolean;
  isCreateButton?: boolean;
  buttonText?: string;
  buttonIcon?: any;
  bgColor?: string;
  searchPlaceHolder?: string;
  buttonBgColor?: string;
  handleSearchClear?: (_: any) => void;
};

export default MainContainerWrapper;

import "./App.scss";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NavigationScroll from "container/layout/NavigationScroll";
import theme from "asset/themes";
import { useSelector } from "react-redux";
import { SetAxiosDefauls } from "helpers/interceptor";
import RoutePath from "routes";
import { connectSocket, socket } from 'utils/socket.utils';
import { useContext, useEffect } from "react";
import { KeycloackContext } from "pages/authentication/keycloak/useKeyCloak";
import { clearStore } from "utils/redux.utils";

function App() {
  const customization = useSelector((state: any) => state.customization);
  const {  keycloakValue } = useContext(KeycloackContext);
  SetAxiosDefauls();
  // connectSocket();

  useEffect(() => {
    keycloakValue.onAuthLogout = () => {
      clearStore();
      localStorage.clear();
      sessionStorage.clear();
    };
 
    return () => {
      // Clean up event listeners on component unmount
      keycloakValue.onAuthLogout = null;
    };
  }, []);

  // useEffect(() => {
  //   socket.on("create_entity", (body) => {
  //     console.log("Created", body);
  //   })

  //   socket.on("approve_entity", (body) => {
  //     console.log("Created", body);
  //   })
  // }, []);

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme(customization)}>
          <CssBaseline>
            <NavigationScroll>
              <RoutePath />
            </NavigationScroll>
          </CssBaseline>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;

import { Box } from "@mui/material";
import QrVerifyComponent from "atic-common-helpers/component/qr-code/QrVerify";

const QrVerify = () => {
  return (
    <>
      {/* <MainContainerWrapper headingText={""}> */}
      <Box height={"100vh"}>
        <QrVerifyComponent />
      </Box>
      {/* </MainContainerWrapper> */}
    </>
  )
}

export default QrVerify;
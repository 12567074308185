import { Box, Button, Typography } from "@mui/material";
import Logo from "asset/images/dc_logo.svg";
import OtpInput from "react-otp-input";
import "../auth-forms/form.scss";
import { useEffect, useState } from "react";
import { Failed, Success } from "atic-common-helpers/helpers/toast.helper";
import { useParams } from "react-router-dom";
import { commonService } from "atic-common-helpers/helpers/common.service";
import SweetAlertComponent from "component/HOC/Alert";

const QrVerifyComponent = () => {
  const params = useParams();
  console.log("params", params);
  const [message, setMessage] = useState<string>("");

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const enteredKey = event.key;
    if (!/^[0-9]$/.test(enteredKey)) {
      event.preventDefault();
    }
  };

  const [otp, setOtp] = useState<string>("");
  const [verifing, setVerify] = useState<boolean>(false);

  const updateStatus = async () => {
    await commonService.putService(`/v2/qr-verification/status`, {
      hash: params?.id,
      status: "Progress",
    });
  };

  const getQrStatus = async () => {
    try {
      const status = await commonService.getServices(
        `/v2/qr-verification/get_status/${params?.id}`
      );
      const data = status?.data?.data;
      if (data?.status == "Initiated") {
        updateStatus();
      } else if (data?.status == "Verified") {
        SweetAlertComponent({
          status: 200,
          message: "Invalid request please try again",
          confirmButtonText: "Ok",
          title: "",
          onSuccess: () => {
            window.opener = null;
            window.open("", "_self");
            window.close();
          },
        });
      } else if (data?.status == "Progress") {
        updateStatus();
      } else if (data?.status == "Failed") {
        SweetAlertComponent({
          status: 200,
          message: "Invalid request please try again",
          confirmButtonText: "Ok",
          title: "",
          onSuccess: () => {
            window.opener = null;
            window.open("", "_self");
            window.close();
          },
        });
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    const updateStatus = async () => {
      await commonService.putService(`/v2/qr-verification/status`, {
        hash: params?.id,
        status: "Progress",
      });
    };
    updateStatus();
  }, []);

  const verifyQr = async () => {
    try {
      setVerify(true);
      const verified = await commonService.postService(
        `/v2/qr-verification/verify_qr`,
        {
          hash: params?.id,
          pin: otp,
        }
      );

      if (!!verified) {
        setOtp("");
        setTimeout(() => {
          window.opener = null;
          window.open("", "_self");
          window.close();
        }, 5000);
        Success("QR Verification Completed Successfully. Please close this browser window. Thanks");
      }
      setVerify(false);
    } catch (err: any) {
      setVerify(false);
      console.log(err);
      if (err?.response?.data?.message) {
        Failed(err?.response?.data?.message);
      } else {
        Failed("Something went wrong, Please try again later.");
      }
    }
  };

  return (
    <>
      <Box
        px={2}
        py={2}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        height={"100%"}
      >
        <Box
          width={"40%"}
          height={"60%"}
          boxShadow={5}
          py={3}
          mx={3}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          sx={{
            width: {
              xs: "100%",
              lg: "40%",
              md: "50%",
            },
            boxShadow: {
              xs: "none",
              lg: 5,
              md: 5,
            },
            height: {
              xs: "80%",
              lg: "60%",
              md: "70%",
            },
          }}
        >
          <img
            src={Logo}
            style={{
              width: "50%",
              height: "20%",
            }}
          />
          <Typography variant="h2" pt={3} sx={{ fontWeight: 700, mb: "1rem" }}>
            QR verification Process
          </Typography>
          <Typography
            mx={3}
            pt={2}
            variant="subtitle1"
            sx={{
              fontWeight: 800,
              opacity: "0.5",
              fontSize: "1rem",
              mx: {
                xs: 2,
              },
            }}
            textAlign={"center"}
          >
            To complete the verification process, Enter the OTP and click the
            below button
          </Typography>
          <form noValidate>
            <Box
              pt={2}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <OtpInput
                numInputs={4}
                value={otp}
                onChange={setOtp}
                renderSeparator={<span> </span>}
                renderInput={(props: any) => (
                  <input
                    onKeyPress={handleKeyPress}
                    type={"number"}
                    {...props}
                  />
                )}
                inputStyle="otp_input_box"
                inputType="number"
              />
            </Box>
            <Box
              pt={3.5}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Button
                onClick={verifyQr}
                disabled={verifing ? verifing : otp.length < 4}
                variant="contained"
              >
                Verify QR
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default QrVerifyComponent;

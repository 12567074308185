export interface IMenuItems {
  id: string;
  title: string;
  type: string;
  url: string;
  icon: any;
  breadcrumbs: boolean;
  children?: any;
  parentId?: string;
  href?: boolean;
  appName?: any;
  backServices?: any;
  activeItem?: string[];
  isActive?: boolean;
  backServicesHref?: boolean
}

export interface IPopupItems {
  path?: string;
  icon: string;
  title: string;
  isLogout?: boolean;
  isChangePassword?: boolean;
  isProtal?: boolean;
  isProfile?: boolean;
  isDisabled?: boolean;
  isBadge?: boolean;
  isMyWallet?: boolean;
}

export interface IPopupList {
  driver: IPopupItems[];
  employee: IPopupItems[];
  admin: IPopupItems[];
}

export interface IPaginationArray {
  array: any[];
  page: number;
  limit: number;
}

export interface LegacySearchType {
  handleCancel: any;
  handleSelected: any;
  isInitialStatus?: boolean;
  isDongle?: string;
}

export enum IBadge {
  AGENT = "agent",
  VEHICLE_OWNER = "vehicle_owner",
  FLEET_OPERATOR = "fleet_operator",
  DRIVER = "driver",
  SERVICE_PROVIDER = "service_provider",
}

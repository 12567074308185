// assets

const driver = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "Verification",
      title: "Verification",
      type: "item",
      url: "driver/verification",
      icon: "description",
      breadcrumbs: false,
    },
  ],
};

const driverVerified = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "Coupons",
      title: "Coupons",
      type: "item",
      url: "driver/coupons",
      icon: "description",
      breadcrumbs: false,
      activeItem: ['/driver/coupons', '/driver/view-profile']
    },
  ],
};
export { driver, driverVerified };

import { useState, useEffect, createContext } from "react";
import Keycloak from "keycloak-js";
import { clearStore } from "utils/redux.utils";
import { useNavigate } from "react-router-dom";
import { commonService } from "atic-common-helpers/helpers/common.service";
import { Failed } from "atic-common-helpers/helpers/toast.helper";
import { errorMessage } from "atic-common-helpers/helpers/function.helper";

const KeycloackContext = createContext();

const keycloakValue = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
});

const headers = {
  "x-api-key": process.env.REACT_APP_SSO_API_KEY,
  "app-name": process.env.REACT_APP_SSO_APP_NAME,
};

const KeycloackContextProvider = (props) => {
  const [keycloak, setKeycloakValue] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  const setKeyclock = () => {
    keycloakValue
      .init({
        // onLoad: "check-sso",
      })
      .then((authenticated) => {
        setKeycloakValue(keycloakValue);
        setAuthenticated(authenticated);
      })
      .catch((error) => {
        console.error("Error during Keycloak initialization:", error);
      });
  };
  useEffect(() => {
    setKeyclock();
  }, []);

  const logout = async () => {
    try {
      const keycloakLogoutResponse = await commonService.postService(
        `/v2/sso/auth/logout`,
        {},
        true,
        "sso",
        headers
      );
      if (!!keycloakLogoutResponse) {
        setKeycloakValue(null);
        setAuthenticated(false);
        keycloak.logout({
          redirectUri:`${process.env.REACT_APP_EXCELSIOR_HOME_PAGE_URL}`,
        })
        clearStore();
        localStorage.clear();
        sessionStorage.clear();
        // navigate("/login");
      }
    } catch (error) {
      console.log(error);
      Failed(
        errorMessage(error, "Something went wrong, please try again later")
      );
    }
  };

  return (
    <KeycloackContext.Provider
      value={{
        keycloak,
        authenticated,
        logout,
        keycloakValue,
      }}
    >
      {props["children"]}
    </KeycloackContext.Provider>
  );
};

export { KeycloackContextProvider, KeycloackContext, keycloakValue };

import dayjs from "dayjs";
import { IPaginationArray } from "./interface.helper";
import { booleanToStringsInObject, dateFormatForm, formatSsn, formatZipcode, parsePhoneNumber } from "atic-common-helpers/hooks/customHook";
import customParseFormat from 'dayjs/plugin/customParseFormat';

export const errorMessage = (error: any, defaultMessage: string): any => {
  if (error?.response?.data?.message) {
    if (Array.isArray(error?.response?.data?.message)) {
      const messageSplit = error?.response?.data?.message[0]?.split(
        " "
      ) as string[];
      if (messageSplit[0].includes(".")) {
        const keysplit = messageSplit[0].split(".");
        const capitalize = capitalizeFirstLetter(keysplit[1]);
        messageSplit.shift();
        messageSplit.unshift(capitalize);
        return messageSplit.toString().replaceAll(",", " ");
      }
      return error?.response?.data?.message[0];
    }
    return error?.response?.data?.message;
  } else if (error?.data?.message) {
    return error?.data?.message;
  }
  return defaultMessage;
};

export const getDriverFormDetails = (data: any) => {
  return {
    firstname: data?.firstname,
    middlename: data?.middlename,
    lastname: data?.lastname,
    email: data?.email,
    gender: data?.gender,
    ssn: data?.ssn,
    dob: data?.dob ? dayjs(data?.dob) : null as any,
    mobile_number: data?.mobile_number,
    country_of_birth: data?.country_of_birth
      ? ({ name: data?.country_of_birth, value: data?.country_of_birth } as any)
      : null,
    home_phone: data?.home_phone,
    office_phone: data?.office_phone,
    motorist_id_licensing_getDriverFormDetailsstate: data?.motorist_id_licensing_state
      ? ({ name: data?.motorist_id_licensing_state, value: data?.motorist_id_licensing_state } as any)
      : null,
    driver_license_number: data?.driver_license_number,
    license_issue_date: data?.license_issue_date ? dayjs(data?.license_issue_date) : null as any,
    license_expiration_date: data?.license_expiration_date ? dayjs(data?.license_expiration_date) : null as any,
    license_type: data?.license_type
      ? ({ name: data?.license_type, value: data?.license_type } as any)
      : null,
    hack_license_expiration_date: data?.hack_license_expiration_date ? dayjs(data?.hack_license_expiration_date) : null as any,
    ddc: data?.ddc,
    ddc_file_name: data?.ddc_file_name,
    address_line_1: data?.address_line_1,
    city: data?.city,
    state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    zip_code: data?.zip_code,
    experience: data?.experience,
    hack_license_number: data?.hack_license_number,
    address_line_2: data?.address_line_2,
    // base_name: data?.base_name ? data?.base_name : null,
    defensive_credit: data?.defensive_credit,
    safe_credit: data?.safe_credit,
    email_verified: data?.email_verified,
    mobile_verified: data?.mobile_verified
  };
};

export const capitalizeFirstLetter = (string: string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const sortData = (
  array: any[],
  key: string,
  sortBy: string,
  type?: string
) => {
  // return array.sort((a, b) => compare(a, b, key, sortBy));
  if (sortBy === "asc") {
    if (type === "number") {
      return array.sort((a, b) => a[key] - b[key]);
    }
    return array.sort((a, b) => a[key]?.localeCompare(b[key]));
  } else {
    if (type === "number") {
      return array.sort((a, b) => b[key] - a[key]);
    }
    return array.sort((a, b) => b[key]?.localeCompare(a[key]));
  }
};

function compare(a: any, b: any, key: string, sortBy: string) {
  if (key === "dob") {
    if (new Date(a[key]) < new Date(b[key]) && sortBy === "desc") {
      return -1;
    }
    if (new Date(a[key]) > new Date(b[key]) && sortBy === "asc") {
      return 1;
    }
    return 0;
  }
  if (a[key] < b[key] && sortBy === "desc") {
    return -1;
  }
  if (a[key] > b[key] && sortBy === "asc") {
    return 1;
  }
  return 0;
}

export const pagination = ({
  array,
  page = 1,
  limit = 20,
}: IPaginationArray) => {
  const page_number = page && page > 0 ? page + 1 : page === 0 ? 1 : page;
  return array.slice((page_number - 1) * limit, page_number * limit);
};

export const cutFileName = (filename: string, maxLength: number, start: number, end: number): string => {
  if (filename.length <= maxLength) {
    return filename;
  }
  const truncatedName = filename.slice(0, start);
  return `${truncatedName}...${filename.slice(-end)}`;
}

/* find the object based on the value */
export const findObject = (value: any, key: any, arrayData: any) => {
  try {
    const findObject = arrayData.find((item: any) => item[key] == value)
    return findObject;
  } catch (error) {
    return {
      name: null,
      value: null
    }
  }
}

/* set profile details */
export const setProfileDetail = (data: any) => {
  return {
    firstname: data?.firstname && data?.firstname,
    middlename: data?.middlename && data?.middlename,
    lastname: data?.lastname && data?.lastname,
    email: data?.email && data?.email,
    gender: data?.gender && data?.gender,
    ssn: data?.ssn && formatSsn(data?.ssn),
    dob: data?.dob ? dayjs(data?.dob) : null as any,
    mobile_number: data?.mobile_number && data?.mobile_number,
    country_of_birth: data?.country_of_birth
      ? ({ name: data?.country_of_birth, value: data?.country_of_birth } as any)
      : null,
    home_phone: data?.home_phone && data?.home_phone,
    office_phone: data?.office_phone && data?.office_phone,
    english_fluency: data?.english_fluency && (data?.english_fluency)?.toString() || null,
    preferred_language: data?.preferred_language && data?.preferred_language,
    other_language: data?.other_language && data?.other_language,
    secondary_phone: data?.secondary_phone && data?.secondary_phone,
    tnc_affiliation: data?.tnc_affiliation && data?.tnc_affiliation,
    address_line_1: data?.address_line_1 && data?.address_line_1,
    address_line_2: data?.address_line_2 && data?.address_line_2,
    city: data?.city && data?.city,
    state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    zip_code: data?.zip_code && formatZipcode(data.zip_code),
    version: data?.version,
  }
}

export const profilePayload = (payload: any) => {
  const phoneNumber = parsePhoneNumber(payload.mobile_number);
  const secondaryNumber = parsePhoneNumber(payload.secondary_phone)
  return {
    ...payload,
    /* take state and country of birth object to string */
    state: payload?.state?.name,
    country_of_birth: payload?.country_of_birth?.name,
    /* string value to convert the boolean */
    english_fluency: Boolean(payload?.english_fluency),
    /* sperate the mobile_number to country and mobile number */
    country_code: phoneNumber?.countryCode,
    mobile_number: phoneNumber?.mobileNumber,
    secondary_code: secondaryNumber?.countryCode,
    secondary_phone: secondaryNumber?.mobileNumber,
    ssn: payload?.ssn?.replace(/-/g, ''),
    zip_code: payload?.zip_code?.replace(/-/g, ''),
    /* convert the date formet */
    dob: payload.dob ? dayjs(payload.dob).format('YYYY-MM-DD') : null
  }
}

export const payloadSplit = (payload: any) => {
  try {
    const profile: any = {
      firstname: payload?.firstname,
      lastname: payload?.lastname,
      middlename: payload?.middlename,
      gender: payload?.gender,
      dob:  payload?.dob?.$d == "Invalid Date" ? null : payload?.dob ? payload?.dob : null,
      country_code: payload?.country_code,
      mobile_number: payload?.mobile_number,
      office_phone: payload?.office_phone,
      home_phone: payload?.home_phone,
      secondary_code: payload?.secondary_code,
      secondary_phone: payload?.secondary_phone,
      tnc_affiliation: payload?.tnc_affiliation,
      email: payload.email,
      country_of_birth: payload?.country_of_birth,
      ssn: payload?.ssn,
      other_language: payload?.other_language,
      preferred_language: payload?.preferred_language,
      english_fluency: Boolean(payload?.english_fluency),
      address_line_1: payload?.address_line_1,
      address_line_2: payload?.address_line_2,
      city: payload?.city,
      state: payload?.state,
      zip_code: payload?.zip_code,
      version: payload?.version && payload?.version
    }

    /* remove the profile data in payload */
    delete payload?.firstname;
    delete payload?.lastname;
    delete payload?.middlename;
    delete payload?.gender;
    delete payload?.dob;
    delete payload?.country_code;
    delete payload?.mobile_number;
    delete payload?.office_phone;
    delete payload?.home_phone;
    delete payload?.email;
    delete payload?.country_of_birth;
    delete payload?.ssn;
    delete payload?.preferred_language;
    delete payload?.other_language;;
    delete payload?.english_fluency;
    delete payload?.secondary_code;
    delete payload?.secondary_phone;
    delete payload?.tnc_affiliation
    delete payload?.address_line_1;
    delete payload?.address_line_2;
    delete payload?.city;
    delete payload?.state;
    delete payload?.zip_code;
    delete payload?.version


    if (!profile.email) {
      delete profile.email
    }

    /* remaining payload details */
    const others = { ...payload }

    return {
      profilePayload: profile,
      others: others,
    };
  } catch (err) {
    return {
      profilePayload: null,
      others: null,
    };
  }
}

/* set agent details */
export const setAgentDetail = (data: any) => {
  return {
    businessType: data.is_individual ? "individual" : "role_house",
    dfs_insurence_license: data?.dfs_insurence_license ? data?.dfs_insurence_license : null,
    business_name: data?.business_name && data?.business_name,
    insurance_class: data?.insurance_class && data?.insurance_class,
    business_email: data?.business_email && data?.business_email,
    office_number: data?.office_number && data?.office_number,
    federal_id: data?.federal_id && data?.federal_id,
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    is_business_email_copied: data?.is_business_email_copied,
    address_line_one: data?.address_line_one && data?.address_line_one,
    address_line_two: data?.address_line_two && data?.address_line_two,
    business_city: data?.city && data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_zip_code: data.zip_code && formatZipcode(data.zip_code),
  }
}

/* set agent business details */
export const setAgentBusinessDetails = (data: any) => {
  return{
    businessType: data.is_individual ? "individual" : "role_house",
    dfs_insurence_license: data?.dfs_insurence_license ? data?.dfs_insurence_license : null,
    business_name: data?.business_name && data?.business_name,
    entity_type: data?.entity_type,
    office_number: data?.office_number && data?.office_number,
    federal_id: data?.federal_id && data?.federal_id,
    business_email: data?.business_email && data?.business_email,
    entity_dbas_name: data?.entity_dbas_name,
    duns_number: data?.duns_number,
    website: data?.website,
    is_business_email_copied: data?.is_business_email_copied ? data?.is_business_email_copied : false,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    is_business_address_copied: data?.is_business_address_copied,
    address_line_one: data?.address_line_one && data?.address_line_one,
    address_line_two: data?.address_line_two && data?.address_line_two,
    business_city: data?.city && data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_zip_code: data.zip_code && formatZipcode(data.zip_code),
    state_of_incorporation :  data?.state_of_incorporation,
        dept_of_state_id :  data?.dept_of_state_id,
        dfs_license : data?.dfs_license,
        ny_state_license : data?.ny_state_license,
  }
}

/* set agent individual details */
export const setAgentIndividualDetails = (data: any) =>{
  return{
    businessType: data.is_individual ? "individual" : "role_house",
    government_id_type: data?.government_id_type,
    id_number: data?.id_number,
    office_number: data?.office_number && data?.office_number,
    insurance_class: data?.insurance_class,
    ny_license_number: data?.ny_license_number,
    // federal_id: "",
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    address_line_one: data?.address_line_one && data?.address_line_one,
    address_line_two: data?.address_line_two && data?.address_line_two,
    business_city: data?.city && data?.city,
    dfs_license : data?.dfs_license,
        ny_state_license : data?.ny_state_license,
        dfs_insurence_license: data?.dfs_insurence_license ? data?.dfs_insurence_license : null,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_zip_code: data.zip_code && formatZipcode(data.zip_code),
  }
}

/* set profile address set as the agent business */
export const setProfileAddressasAgentBusiness = (data: any) => {
  return{
    address_line_one: data?.address_line_1 && data?.address_line_1,
    address_line_two: data?.address_line_2 && data?.address_line_2,
    business_city: data?.city && data?.city,
    business_state: data?.state ? data?.state : null,
    business_zip_code: data?.zip_code && formatZipcode(data.zip_code),
  }
}

/* un-set profile address in agent business */
export const removeProfileAddressasAgentBusiness = () => {
  return{
    address_line_one: "",
    address_line_two: "",
    business_city: "",
    business_state: null,
    business_zip_code: null,
  }
}

/* set driver details */
export const setDriverDetail = (data: any) => {
  return {
    hack_license_number: data.hack_license_number,
    hack_license_expiration_date: data?.hack_license_expiration_date ? dayjs(data?.hack_license_expiration_date) : null as any,
    // base_name: data.base_name,
    dmv_license_number: data.dmv_license_number,
    dmv_license_state: data?.dmv_license_state,
    dmv_license_issue_date: data?.dmv_license_issue_date ? dayjs(data?.dmv_license_issue_date) : null as any,
    dmv_license_expiration_date: data?.dmv_license_expiration_date ? dayjs(data?.dmv_license_expiration_date) : null as any,
    policy_number: data?.policy_number ? data?.policy_number : '',
    policy_effective_date: data?.policy_effective_date ? data?.policy_effective_date : null,
    policy_expiration_date: data?.policy_expiration_date ? data?.policy_expiration_date : null,
  }
}

/* set fleet operator */
export const setFleetOperator = (data: any) => {
  return {
    company_name: data?.company_name,
    fleet_email: data?.email,
    fleet_office_number: data?.fleet_office_number,
    bic: data?.bic,
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    is_business_email_copied: data?.is_business_email_copied,
    address: data?.address,
    address_line2: data?.address_line2,
    fleet_city: data?.city,
    fleet_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    fleet_country: data?.country
      ? ({ name: data?.country, value: data?.country } as any)
      : null,
    fleet_zip_code: data?.zip_code ? formatZipcode(data?.zip_code) : null,
  }
}

/* set fleet operator business */
export const setFleetOperatorBusiness = (data: any) => {
  return {
    company_name: data?.company_name,
    entity_type: data?.entity_type,
    fleet_office_number: data?.fleet_office_number,
    bic: data?.bic,
    fleet_email: data?.email,
    entity_dbas_name: data?.entity_dbas_name,
    duns_number: data?.duns_number,
    website: data?.website,
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    is_business_email_copied: data?.is_business_email_copied,
    address: data?.address,
    address_line2: data?.address_line2,
    fleet_city: data?.city,
    fleet_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    fleet_country: data?.country
      ? ({ name: data?.country, value: data?.country } as any)
      : null,
    fleet_zip_code: data?.zip_code ? formatZipcode(data?.zip_code) : null,
    state_of_incorporation :  data?.state_of_incorporation,
    dept_of_state_id :  data?.dept_of_state_id,
    dfs_license : data?.dfs_license,
    ny_state_license : data?.ny_state_license,
    dfs_insurence_license : data?.dfs_insurence_license,
  }
}

/* set fleet operator individual */
export const setFleetOperatorIndividual = (data: any) => {
  return {
    government_id_type: data?.government_id_type,
    id_number: data?.id_number,
    fleet_office_number: data?.fleet_office_number,
    ny_license_number: data?.ny_license_number,
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    address: data?.address,
    address_line2: data?.address_line2,
    fleet_city: data?.city,
    fleet_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    fleet_country: data?.country
      ? ({ name: data?.country, value: data?.country } as any)
      : null,
    fleet_zip_code: data?.zip_code ? formatZipcode(data?.zip_code) : null,

    dfs_license : data?.dfs_license,
    ny_state_license : data?.ny_state_license,
    dfs_insurence_license: data?.dfs_insurence_license ? data?.dfs_insurence_license : null,
  }
}

/* set profile address set as the fleet operator business */
export const setProfileAddressasFleetOperatorBusiness = (data: any) => {
  return{
    address: data?.address_line_1 && data?.address_line_1,
    address_line2: data?.address_line_2 && data?.address_line_2,
    fleet_city: data?.city && data?.city,
    fleet_state: data?.state ? data?.state : null,
    fleet_zip_code: data?.zip_code && formatZipcode(data.zip_code),
  }
}

/* un-set profile address in fleet operator business */
export const removeProfileAddressasFleetOperatorBusiness = () => {
  return{
    address: "",
    address_line2: "",
    fleet_city: "",
    fleet_state: null,
    fleet_zip_code: null,
  }
}

/* set vehicle onwer */
export const setVehicleOnwer = (data: any) => {
  return {
    business_name: data?.business_name,
    bic_number: data?.bic_number,
    office_number: data?.office_number,
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    address_line_one: data?.address_line_1,
    address_line_two: data?.address_line_2,
    business_city: data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_zip_code: data?.zip_code ? formatZipcode(data.zip_code) : null,
  }
}

/* set vehicle onwer business */
export const setVehicleOnwerBusiness = (data: any) => {
  return {
    business_name: data?.business_name,
    entity_type: data?.entity_type,
    bic_number: data?.bic_number,
    office_number: data?.office_number,
    business_email: data?.business_email,
    entity_dbas_name: data?.entity_dbas_name,
    duns_number: data?.duns_number,
    website: data?.website,
    is_business_email_copied: data?.is_business_email_copied,
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    address_line_one: data?.address_line_1,
    address_line_two: data?.address_line_2,
    business_city: data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_zip_code: data?.zip_code ? formatZipcode(data.zip_code) : null,
    state_of_incorporation :  data?.state_of_incorporation,
    dept_of_state_id :  data?.dept_of_state_id,
    dfs_license : data?.dfs_license,
    ny_state_license : data?.ny_state_license,
    dfs_insurence_license: data?.dfs_insurence_license ? data?.dfs_insurence_license : null,
  }
}

/* set vehicle onwer individual */
export const setVehicleOnwerIndividual = (data: any) => {
  return {
    government_id_type: data?.government_id_type,
    id_number: data?.id_number,
    office_number: data?.office_number,
    ny_license_number: data?.ny_license_number,
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    address_line_one: data?.address_line_1,
    address_line_two: data?.address_line_2,
    business_city: data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_zip_code: data?.zip_code ? formatZipcode(data.zip_code) : null,
    dfs_license : data?.dfs_license,
        ny_state_license : data?.ny_state_license,
        dfs_insurence_license: data?.dfs_insurence_license ? data?.dfs_insurence_license : null,
  }
}

/* set profile address set as the vehicle owner business */
export const setProfileAddressasVehicleOnwerBusiness = (data: any) => {
  return{
    address_line_one: data?.address_line_1 && data?.address_line_1,
    address_line_two: data?.address_line_2 && data?.address_line_2,
    business_city: data?.city && data?.city,
    business_state: data?.state ? data?.state : null,
    business_zip_code: data?.zip_code && formatZipcode(data.zip_code),
  }
}

/* un-set profile address in vehicle owner business */
export const removeProfileAddressasVehicleOwnerBusiness = () => {
  return{
    address_line_one: "",
    address_line_two: "",
    business_city: "",
    business_state: null,
    business_zip_code: null,
  }
}

const dateFnc = (data: any) => {

  const [day, month, year] = data.split('/');
  const newDateString = `${month}/${day}/${year}`;

  // Create a Date object from the new date string
  const dateObject = new Date(newDateString);
  const convertedDateString = dateFormatForm(dateObject);
  const formattedDate = dayjs(convertedDateString);
  if (formattedDate.isValid()) {
    console.log(formattedDate); // Output: 2026-03-13T00:00:00+00:00
    return formattedDate;
  } else {
    console.error("Invalid date format");
    return null;
  }
}
/* set driver detail from the legacy */
export const setLegacyDriver = (data: any) => {

  return {
    firstname: data?.firstname,
    middlename: data?.middlename,
    lastname: data?.lastname,
    dmv_license_number: data?.dmv_license_number && data?.dmv_license_number,
    dmv_license_issue_date: data?.dmv_license_issue_date ?
      dateFnc(data?.dmv_license_issue_date) : null as any,
    dmv_license_expiration_date: data?.dmv_license_expiration_date ?
      dateFnc(data?.dmv_license_expiration_date) : null as any,
    // base_name: data?.base_name,
    hack_license_number: data?.hack_license_number,
    hack_license_expiration_date: data?.hack_license_expiration_date
      ? dateFnc(data?.hack_license_expiration_date) : null as any,
  }
}

/* set service provider details */
export const setServiceProvider = (data: any) => {
  return {
    business_name: data?.business_name,
    tax_id: data?.tax_id,
    office_number: data?.office_number,
    npi_number: data?.npi_number,
    medical_provider_type: data?.medical_provider_type
      ? ({ name: data?.medical_provider_type, value: data?.medical_provider_type } as any)
      : null,
    service_provider_type: data?.service_provider_type,
    attorney_license_number: data?.attorney_license_number,
    license_number: data?.license_number,
    shop_registration_number: data?.shop_registration_number,
    shop_type: data?.shop_type,
    manager_name: data?.manager_name,
    manager_contact_number: data?.manager_contact_number,
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    address_line_one: data?.address_line_1,
    address_line_two: data?.address_line_2,
    business_city: data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_country: data?.country
      ? ({ name: data?.country, value: data?.country } as any)
      : null,
    business_zip_code: formatZipcode(data.zip_code),
  }
}

/* set service provider details business */
export const setServiceProviderBusiness = (data: any) => {
  return {
    business_name: data?.business_name,
    entity_type: data?.entity_type,
    office_number: data?.office_number,
    tax_id: data?.tax_id,
    business_email: data?.business_email,
    service_provider_type: data?.service_provider_type,
    medical_provider_type: data?.medical_provider_type
      ? ({ name: data?.medical_provider_type, value: data?.medical_provider_type } as any)
      : null,
    npi_number: data?.npi_number,
    attorney_license_number: data?.attorney_license_number,
    license_number: data?.license_number,
    shop_registration_number: data?.shop_registration_number,
    shop_type: data?.shop_type,
    manager_name: data?.manager_name,
    manager_contact_number: data?.manager_contact_number,
    entity_dbas_name: data?.entity_dbas_name,
    duns_number: data?.duns_number,
    website: data?.website,
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    address_line_one: data?.address_line_1,
    address_line_two: data?.address_line_2,
    business_city: data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_country: data?.country
      ? ({ name: data?.country, value: data?.country } as any)
      : null,
    business_zip_code: formatZipcode(data.zip_code),
    ny_state_license : data?.ny_state_license,
    vehicle_maintanence : data?.vehicle_maintanence,
    medical_license : data?.medical_license,
    attorney_license : data?.attorney_license,
    gas_station_license:data?.gas_station_license,
    state_of_incorporation : data?.state_of_incorporation,
    dept_of_state_id : data?.dept_of_state_id
  }
}

/* set service provider details individual */
export const setServiceProviderIndividual = (data: any) => {
  return {
    government_id_type: data?.government_id_type,
    id_number: data?.id_number,
    office_number: data?.office_number,
    service_provider_type: data?.service_provider_type,
    medical_provider_type: data?.medical_provider_type
    ? ({ name: data?.medical_provider_type, value: data?.medical_provider_type } as any)
    : null,
    attorney_license_number: data?.attorney_license_number,
    npi_number: data?.npi_number,
    license_number: data?.license_number,
    shop_registration_number: data?.shop_registration_number,
    shop_type: data?.shop_type,
    manager_name: data?.manager_name,
    manager_contact_number: data?.manager_contact_number,
    ny_license_number: data?.ny_license_number,
    is_business_address_copied: data?.is_business_address_copied,
    is_business_mobile_number_copied: data?.is_business_mobile_number_copied,
    address_line_one: data?.address_line_1,
    address_line_two: data?.address_line_2,
    business_city: data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_country: data?.country
      ? ({ name: data?.country, value: data?.country } as any)
      : null,
    business_zip_code: formatZipcode(data.zip_code),
    ny_state_license : data?.ny_state_license,
    vehicle_maintanence : data?.vehicle_maintanence,
    medical_license : data?.medical_license,
    attorney_license : data?.attorney_license,
    gas_station_license:data?.gas_station_license,
    state_of_incorporation : data?.state_of_incorporation,
    dept_of_state_id : data?.dept_of_state_id
  }
}

/* set profile address set as the service provider business */
export const setProfileAddressasServiceProviderBusiness = (data: any) => {
  return{
    address_line_one: data?.address_line_1 && data?.address_line_1,
    address_line_two: data?.address_line_2 && data?.address_line_2,
    business_city: data?.city && data?.city,
    business_state: data?.state ? data?.state : null,
    business_zip_code: data?.zip_code && formatZipcode(data.zip_code),
  }
}

/* un-set profile address in service provider business */
export const removeProfileAddressasServiceProviderBusiness = () => {
  return{
    address_line_one: "",
    address_line_two: "",
    business_city: "",
    business_state: null,
    business_zip_code: null,
  }
}

/* set vehicle detail from the legacy */
export const setLegacyVehicle = (data: any) => {
  return {
    id: data?.id,
    vehicle_identification_number: data?.vehicle_identification_number,
    vehicle_class: data?.vehicle_class,
    fuel_type: data?.fuel_type,
    vehicle_make: data?.vehicle_make,
    vehicle_model: data?.vehicle_model,
    vehicle_year: data?.vehicle_year
      ? ({ name: data?.vehicle_year, value: data?.vehicle_year } as any)
      : null,
    weight: data?.weight,
    safety_rating: data?.safety_rating,
    seating_capacity: data?.seating_capacity,
    vehicle_license_number: data?.vehicle_license_number,
    // dmv_license_plate_number: data?.dmv_license_plate_number,
    tlc_permit_license_number: data?.tlc_permit_license_number ? data?.tlc_permit_license_number : null,
    vehicle_owner: data?.vehicle_owner,
  }
}

/* set vehicle detail from the legacy */
export const setVehicleAdditionalDetails = (data: any) => {
  const vehicle = {
    driver_cam: data?.driver_cam,
    obd_plug_in: data?.obd_plug_in ? data?.obd_plug_in : null,
    dongle: data?.dongle ? data?.dongle : null,
    black_box: (data?.black_box),
    gps_navigation: (data?.gps_navigation),
    bluetooth: (data?.bluetooth),
    base_name: data?.base_name ? data?.base_name : null,
    automatic_braking: (data?.automatic_braking),
    lane_depature_control: (data?.lane_depature_control),
    forward_collision_warning: data?.forward_collision_warning,
    lane_departure_warning: data?.lane_departure_warning,
    anti_lock_braking_system: (data?.anti_lock_braking_system),
    electronic_stability_control: (data?.electronic_stability_control),
    traction_control: (data?.traction_control),
    tyre_pressure_monitoring_system: (data?.tyre_pressure_monitoring_system),
    automatic_pedestrian_alert_sound: (data?.automatic_pedestrian_alert_sound),
    automatic_crash_notification: (data?.automatic_crash_notification),
    blind_spot_warning: (data?.blind_spot_warning),
    event_data_recorder: (data?.event_data_recorder),
    wheel_chair_accessibility: (data?.wheel_chair_accessibility),
    vehicle_owner: data?.vehicle_owner,
    vehicle_driver: data?.vehicle_driver,
    vehicle_fleet: data?.vehicle_fleet,
    vehicle_registrant: data?.vehicle_registrant,
    dmv_license_plate_number: data?.dmv_license_plate_number,
    tlc_permit_license_number: data?.tlc_permit_license_number ? data?.tlc_permit_license_number : null,
    current_insurance_carrier: data?.current_insurance_carrier ? { name: data?.current_insurance_carrier, value: data?.current_insurance_carrier } : null,
    policy_number: data?.policy_number,
    policy_effective_date: data?.policy_effective_date ? dayjs(data?.policy_effective_date) : null,
    policy_expiration_date: data?.policy_expiration_date ? dayjs(data?.policy_expiration_date) : null
  }
  return booleanToStringsInObject(vehicle);
}

/* set vehicle permit details */
export const setVehilcePermit = (data: any) => {
  return {
    vehicle_permit_license_number: data?.vehicle_permit_license_number,
    vehicle_tlc_license_type: data?.vehicle_tlc_license_type,
    permit_expiration_date: data?.permit_expiration_date ?
      dayjs(data?.permit_expiration_date) : null as any,
    dmv_license_plate_number: data?.dmv_license_plate_number,
    fhv_vehicle_license_number: data?.fhv_vehicle_license_number,
    license_name: data?.license_name,
    vehicle_class: data?.vehicle_class,
    base_number: data?.base_number,
    base_name: data?.base_name,
    base_type: data?.base_type,
    mobile_number: data?.mobile_number,
    website: data?.website,
    address_line_1: data?.address_line_1,
    address_line_2: data?.address_line_2,
    city: data?.city,
    state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    zip_code: formatZipcode(data?.zip_code),
  }
}

/* set dongle and obd */
export const setDongleandODB = (data: any) => {
  return {
    device_type: data?.device_type,
    dongle_make: data?.dongle_make,
    dongle_model: data?.dongle_model,
    serial_number: data?.serial_number,
    obd_parameter_id: data?.obd_parameter_id,
    obd_version: data?.obd_version,
  }
}
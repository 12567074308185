import QrDisplay from "atic-common-helpers/component/qr-code/QrDisplay";
import { IBadge } from "atic-common-helpers/helpers/interface.helper";
import MainContainerWrapper from "atic-common-helpers/wrappers/MainContainerWrapper";
import { useLocation } from "react-router-dom";

interface IQrVerification {
  type: IBadge;
  header: string;
}

const QrVerification = ({ type, header }: IQrVerification) => {
  const location = useLocation();
  console.log("location", location);
  return (
    <>
      <MainContainerWrapper headingText={`Credentialing - ${header}`}>
        <QrDisplay type={type} userId={location.state.id} entity={location.state.entity} />
      </MainContainerWrapper>
    </>
  );
};

export default QrVerification;

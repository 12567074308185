import { driver, admin, employee, profile, credentialing } from "./constant.utils";

const popupItems: any = {
  driver: driver,
  admin: admin,
  employee: employee,
  profile: profile,
  // credentialing: credentialing,
};

export default popupItems;
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const admin = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "admin/dashboard",
      icon: "gridView",
      breadcrumbs: false,
    },
    {
      id: "user",
      title: "Employees",
      type: "item",
      url: "admin/employee",
      icon: "manageSearch",
      breadcrumbs: false,
      activeItem: ['/admin/employee', '/admin/create-employee', '/admin/edit-employee', '/admin/view-employee']
    },
    {
      id: "coupons",
      title: "Coupons",
      type: "item",
      url: "admin/coupons",
      icon: "description",
      breadcrumbs: false,
      activeItem: ['/admin/coupons', '/admin/create-coupon', '/admin/edit-coupon', '/admin/view-coupon']
    },
    // {
    //     id: 'Chat',
    //     title: 'Chat',
    //     type: 'item',
    //     url: 'admin/chat',
    //     icon: admin2,
    //     breadcrumbs: false
    // },
  ],
};

export default admin;

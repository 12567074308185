import { commonService } from "atic-common-helpers/helpers/common.service";
import { IBadge } from "atic-common-helpers/helpers/interface.helper";
import { Failed } from "atic-common-helpers/helpers/toast.helper";
import Loading from "container/loading";
import { useEffect, useRef, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SweetAlertComponent from "component/HOC/Alert";
import { useNavigate } from "react-router-dom";
import { useAuth } from "routes/auth.provider";
import { useAppSelector } from "store/store";

interface IQrVerification {
  type: IBadge;
  userId: string;
  entity: any;
}

const QrDisplay = ({ type, userId, entity }: IQrVerification) => {
  /* getUser function get from the useContact  */
  const { getUser } = useAuth();
  const user = useAppSelector((state) => state.user.user)

  const [loading, setLoading] = useState<boolean>(true);
  const [qrDetails, setQrDetails] = useState<any>({});
  const [retry, setRetry] = useState<boolean>(false);
  const [interval, setIntervalData] = useState<any>({});
  const [isDisabled, setIsDisabled] = useState(true);
  const counterRef = useRef<any>(null);
  const [countdown, setCountdown] = useState(5 * 60);
  const [initialState, setInitialState] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(
    isMobile
      ? "Please click the button to add credential"
      : "Please scan the QR code"
  );
  let timer: any;

  const navigate = useNavigate();

  const theme = useTheme();

  //mediaquery
  const matchUpMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchDownXS = useMediaQuery(theme.breakpoints.down("sm"));

  const getQrCode = async () => {
    try {
      const qrDetails = await commonService.postService(
        `/v2/qr-verification/init`,
        { type, user: userId, service_id: entity?.id }
      );
      const details = qrDetails.data?.data;
      if (!!details) {
        setQrDetails({
          url: process.env.REACT_APP_URL + "/qr_verification/" + details.qrHash,
          hash: details.qrHash,
          pin: details.pin,
        });
        setLoading(false);
        setRetry(false);
        setInitialState(false);
        startCountdown();
      } else {
        Failed("Something went wrong, Please try again later");
        setRetry(true);
      }
    } catch (err) {
      setLoading(false);
      setRetry(true);
    }
  };

  const getQrStatus = async () => {
    try {
      const status = await commonService.getServices(
        `/v2/qr-verification/get_status/${qrDetails?.hash}`
      );
      console.log("data", status);
      const data = status?.data?.data;
      if (data?.status == "Initiated") {
        setMessage("Please scan the QR code");
      } else if (data?.status == "Verified") {
        setVerified(true);
        SweetAlertComponent({
          status: 200,
          message: "Credentialing completed successfully",
          confirmButtonText: "Ok",
          title: "",
          onSuccess: () => {
            navigate(user?.is_mobile_registered ? '/profile_details' : "/profile/services");
          },
        });
        await getUser();
      } else if (data?.status == "Progress") {
        setMessage(
          "QR code verification initiated. Please enter the pin to complete the progress."
        );
      } else if (data?.status == "Failed") {
        setMessage("QR code verification failed");
        setRetry(true);
      }
    } catch (err: any) {
      setRetry(true);
      console.log(err);
      if (err?.response?.data?.message) {
        Failed(err?.response?.data?.message);
      } else {
        Failed("Something went wrong, Please try again later.");
      }
    }
  };

  useEffect(() => {
    getQrCode();
    return () => clearInterval(timer);
  }, []);

  /* initial stage manangement */
  useEffect(() => {
    if (initialState) {
      return;
    }
    const interval: any = setInterval(() => {
      if (!verified) {
        getQrStatus();
      } else {
        clearInterval(counterRef.current);
      }
    }, 5000);
    counterRef.current = interval;
    return () => clearInterval(counterRef.current);
  }, [initialState, verified]);

  useEffect(() => {
    if (!initialState) {
      console.log(initialState);
      const timeoutId = setTimeout(() => {
        // Perform the action you want after 10 minutes here
        setRetry(true);
        clearInterval(counterRef.current);
        counterRef.current = null;
        setInitialState(true);
        setQrDetails("");
      }, 5 * 60 * 1000); // 5 minutes in milliseconds
      return () => clearTimeout(timeoutId);
    }
  }, [initialState]);

  const startCountdown = () => {
    setIsDisabled(true);
    timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      setIsDisabled(false);
      setCountdown(5 * 60);
      setRetry(true);
    }, 5 * 60 * 1000);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Box px={2} py={2}>
          <Typography variant="h2" sx={{ fontWeight: 700, mb: "1rem" }}>
            Verification Process
          </Typography>

          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            minHeight="55vh"
          >
            <>
              <Box>
                {retry ? (
                  <Button
                    variant="contained"
                    sx={{ mb: 3, textAlign: "center" }}
                    onClick={getQrCode}
                  >
                    Retry
                  </Button>
                ) : (
                  <>
                    {isMobile ? (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        startIcon={
                          <FactCheckIcon
                            style={{ fontSize: "1.5rem", width: "2rem" }}
                          />
                        }
                        onClick={() => window.open(qrDetails?.url, "_blank")}
                      // disabled={qrScanned}
                      >
                        Add Credential
                      </Button>
                    ) : (
                      <Box
                        padding={1}
                        border={`1px solid ${theme.palette.grey[300]}`}
                      >
                        <QRCodeCanvas
                          value={qrDetails?.url}
                          size={220}
                          level={"L"}
                          style={{ margin: "0.5rem" }}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>
              <Typography
                variant="subtitle2"
                fontWeight="700"
                pt={3}
                fontSize="1rem"
              >
                Pin : {!retry ? qrDetails?.pin : "-"}
              </Typography>
              {!isMobile ? (
                <Typography variant="subtitle2" fontSize="1rem" pt={2}>
                  {retry ? "Please Retry" : message}
                </Typography>
              ) : (
                <Typography
                  variant="subtitle2"
                  fontSize="1rem"
                  pt={2}
                  textAlign={isMobile ? "center" : "start"}
                >
                  {retry
                    ? "Please Retry"
                    : isMobile
                      ? `Please click on "Add Credential" to start the authentication process`
                      : "Please add the Credential"}
                </Typography>
              )}
              {!retry && (
                <Stack
                  direction={"row"}
                  justifyContent={{
                    sm: "center",
                    md: "center",
                    lg: "space-between",
                  }}
                  alignItems={"center"}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "700",
                      color: theme.palette.grey[500],
                      // mr: 1,
                      mt: 2,
                    }}
                  >
                    Time Remaining:
                    <span
                      style={{
                        marginLeft: "3px",
                        color: theme.palette.primary.main,
                      }}
                    >{`${new Date(countdown * 1000)
                      .toISOString()
                      .slice(14, 19)}`}</span>
                  </Typography>
                </Stack>
              )}
            </>
          </Stack>
          {/* <Stack
            direction={matchDownMD ? "column-reverse" : "row"}
            justifyContent={matchDownMD ? "center" : "flex-Start"}
            pb={3}
          >
            <Button
              variant="contained"
              color="primary"
              // style={{
              //   backgroundColor: theme.palette.background.default,
              //   color: theme.palette.primary.buttonColor,
              //   fontWeight: 600,
              //   fontSize: "1rem",
              // }}
              onClick={() => console.log("cancel")}
              startIcon={<ArrowBackIosNewIcon style={{ fontSize: "1rem" }} />}
            >
              Back
            </Button>
          </Stack> */}
        </Box>
      )}
    </>
  );
};

export default QrDisplay;

import admin from "./admin";
import employee from "./employee";
import { driver, driverVerified } from "./driver";
import { credentialing, mobileUserProfile, mobileUserProfileNotVerifed, mobileUserProfileVerifed,profile } from "./profile";

const menuItems: any = {
  admin: [admin],
  employee: [employee],
  driver: [driver],
  driverVerified: [driverVerified],
  profile: [profile],
  //kycNotVerified:[kycNotVerified],
  mobileUserProfile: [mobileUserProfile],
  mobileUserProfileVerifed: [mobileUserProfileVerifed],
  mobileUserProfileNotVerifed: [mobileUserProfileNotVerifed],
  credentialing: [credentialing],
};

export default menuItems;
// assets   
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

const employee = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        // {
        //     id: 'default',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: 'broker/dashboard',
        //     icon: GridViewIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'user',
            title: 'Drivers',
            type: 'item',
            url: 'employee/drivers',
            icon: "people",
            breadcrumbs: false,
            activeItem: ['/employee/drivers', '/employee/create-driver', '/employee/edit-driver', '/employee/view-driver']
        },  
    ]
};

export default employee;
